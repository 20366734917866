import React, { Component } from "react";
import { Box, Card, Divider, Paper, SvgIcon, Typography } from "@mui/material";
import DashboardIcon from "@mui/icons-material/Dashboard";
import GroupIcon from "@mui/icons-material/Group";
import logo from "../src/img/logo.png";
import { useNavigate, useLocation, useMatch, Link } from "react-router-dom";
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import CampaignIcon from '@mui/icons-material/Campaign';
import DynamicFormIcon from '@mui/icons-material/DynamicForm';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import SpeedIcon from '@mui/icons-material/Speed';
import SettingsIcon from '@mui/icons-material/Settings';
import BadgeIcon from '@mui/icons-material/Badge';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import PaidIcon from '@mui/icons-material/Paid';
import LogoutIcon from '@mui/icons-material/Logout';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import PeopleIcon from '@mui/icons-material/People';
import SubjectIcon from '@mui/icons-material/Subject';

export class Sidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openLead: false,
      data: "",
      textcolor: "#008ffb",
      bgcolor: "",

      id: 1,
      pathname: this.props.location.pathname,

      opencompaign: false,
      openrole: false,
    };
  }

  render() {
    return (
      <div>
        <Box
          sx={{
            display: { xs: "none", sm: "flex" },
            width: 235,
            bottom: 0,
            height: "100vh",
            position: "sticky",
          }}
        >
          <Box
            sx={{
              width: 240,
              marginLeft: 0,
              marginRight: 0,
              borderRadius: 0,
              borderRightColor:'grey',
              borderWidth : 2,
              position: "fixed",
              height: "100vh",
              overflowY: "scroll",
              scrollbarWidth: "none",
              "&::-webkit-scrollbar": {
                display: "none",
              },
            }}
          >
            <Paper elevation={1} sx={{ height: 60, backgroundColor: "#fff",borderRadius:0 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img style={{height:50,marginTop:5}} src={logo}/>
              
              </Box>
            </Paper>

             <Divider/>
            <Paper
              elevation={0}
              sx={{ minHeight: "100vh", backgroundColor: "#1b75d3", mt:0,borderRadius:0,borderWidth:0.5,borderColor:'grey'}}
            >
              <br />


              <Box
                sx={{
                  marginLeft: 0,
                  marginRight:0,
                  height: 40,
                  backgroundColor:
                    this.state.pathname == "/dashboard" ? "#ebebf5" : "#1b75d3",
                  display: "flex",
                  justifyContent: "left",
                  mt: 1,
                  borderRadius:0,
                  alignItems: "center",
                }}
                onClick={()=>this.props.navigate('/dashboard')}
              >
                <Box
                  sx={{ display: "flex", flexDirection: "row", width: "100%" }}
                >
                  <DashboardIcon
                    sx={{
                      marginLeft: 2,
                      color: this.state.pathname == "/dashboard" ? "#008ffb" : "#fff",
                    }}
                  />
                  <Box sx={{ width: "100%", marginLeft: "25%" }}>
                    <Link style={{ textDecoration: "none" }}>
                      <Typography
                        sx={{
                          textDecoration: "none",
                          textAlign: "left",
                          fontSize: 12,
                          fontWeight: "500",
                          mt:0.5,
                          color:
                            this.state.pathname == "/dashboard" ? "#008ffb" : "#fff",
                          marginLeft: -5,
                        }}
                      >
                        DASHBOARD
                      </Typography>
                    </Link>
                  </Box>
                </Box>
              </Box>




              <Box
                sx={{
                  marginLeft: 0,
                  marginRight: 0,
                  height: 40,
                  backgroundColor:
                  this.state.pathname == "/user" ? "#ebebf5" : "#1b75d3",
                  display: "flex",
                  justifyContent: "left",
                  mt: 1,
                  borderRadius: 0,
                  alignItems: "center",
                }}
                onClick={()=>this.props.navigate('/user')}
              >
                <Box
                  sx={{ display: "flex", flexDirection: "row", width: "100%" }}
                >
                  <PeopleIcon
                    sx={{
                      marginLeft: 2,
                      color: this.state.pathname == "/user" ? "#008ffb" : "#fff",
                    }}
                  />
                  <Box sx={{ width: "100%", marginLeft: "25%" }}>
                    <Link style={{ textDecoration: "none" }}>
                      <Typography
                        sx={{
                          textDecoration: "none",
                          textAlign: "left",
                          fontSize: 12,
                          fontWeight: "500",
                          mt:0.5,
                          color:
                            this.state.pathname == "/user" ? "#008ffb" : "#fff",
                            marginLeft: -5,
                        }}
                      >
                        USERS LIST
                      </Typography>
                    </Link>
                  </Box>
                </Box>
              </Box>



              <Box
                sx={{
                  marginLeft: 0,
                  marginRight: 0,
                  height: 40,
                  backgroundColor:
                  this.state.pathname == "/subject" ? "#ebebf5" : "#1b75d3",
                  display: "flex",
                  justifyContent: "left",
                  mt: 1,
                  borderRadius: 0,
                  alignItems: "center",
                }}
                onClick={()=>this.props.navigate('/subject')}
              >
                <Box
                  sx={{ display: "flex", flexDirection: "row", width: "100%" }}
                >
                  <SubjectIcon
                    sx={{
                      marginLeft: 2,
                      color: this.state.pathname == "/subject" ? "#008ffb" : "#fff",
                    }}
                  />
                  <Box sx={{ width: "100%", marginLeft: "25%" }}>
                    <Link style={{ textDecoration: "none" }}>
                      <Typography
                        sx={{
                          textDecoration: "none",
                          textAlign: "left",
                          fontSize: 12,
                          fontWeight: "500",
                          mt:0.5,
                          color:
                            this.state.pathname == "/subject" ? "#008ffb" : "#fff",
                            marginLeft: -5,
                        }}
                      >
                        SUBJECT LIST
                      </Typography>
                    </Link>
                  </Box>
                </Box>
              </Box>


              <Box
                sx={{
                  marginLeft: 0,
                  marginRight: 0,
                  height: 40,
                  backgroundColor:
                  this.state.pathname == "/question_paper" ? "#ebebf5" : "#1b75d3",
                  display: "flex",
                  justifyContent: "left",
                  mt: 1,
                  borderRadius: 0,
                  alignItems: "center",
                }}
                onClick={()=>this.props.navigate('/question_paper')}
              >
                <Box
                  sx={{ display: "flex", flexDirection: "row", width: "100%" }}
                >
                  <SpeedIcon
                    sx={{
                      marginLeft: 2,
                      color: this.state.pathname == "/question_paper" ? "#008ffb" : "#fff",
                    }}
                  />
                  <Box sx={{ width: "100%", marginLeft: "25%" }}>
                    <Link style={{ textDecoration: "none" }}>
                      <Typography
                        sx={{
                          textDecoration: "none",
                          textAlign: "left",
                          fontSize: 12,
                          fontWeight: "500",
                          mt:0.5,
                          color:
                            this.state.pathname == "/question_paper" ? "#008ffb" : "#fff",
                            marginLeft: -5,
                        }}
                      >
                        QUESTION PAPER 
                      </Typography>
                    </Link>
                  </Box>
                </Box>
              </Box>




              <Box
                sx={{
                  marginLeft: 0,
                  marginRight: 0,
                  height: 40,
                  backgroundColor:
                  this.state.pathname == "/settings" ? "#ebebf5" : "#1b75d3",
                  display: "flex",
                  justifyContent: "left",
                  mt: 1,
                  borderRadius: 0,
                  alignItems: "center",
                }}
                onClick={()=>this.props.navigate('/settings')}
              >
                <Box
                  sx={{ display: "flex", flexDirection: "row", width: "100%" }}
                >
                  <SettingsIcon
                    sx={{
                      marginLeft: 2,
                      color: this.state.pathname == "/settings" ? "#008ffb" : "#fff",
                    }}
                  />
                  <Box sx={{ width: "100%", marginLeft: "25%" }}>
                    <Link style={{ textDecoration: "none" }}>
                      <Typography
                        sx={{
                          textDecoration: "none",
                          textAlign: "left",
                          fontSize: 12,
                          fontWeight: "500",
                          mt:0.5,
                          color:
                            this.state.pathname == "/settings" ? "#008ffb" : "#fff",
                            marginLeft: -5,
                        }}
                      >
                        SETTINGS 
                      </Typography>
                    </Link>
                  </Box>
                </Box>
              </Box>



              <Box
                sx={{
                  marginLeft: 0,
                  marginRight: 0,
                  height: 40,
                  backgroundColor:
                  this.state.pathname == "/transaction" ? "#ebebf5" : "#1b75d3",
                  display: "flex",
                  justifyContent: "left",
                  mt: 1,
                  borderRadius: 0,
                  alignItems: "center",
                }}
                onClick={()=>this.props.navigate('/transaction')}
              >
                <Box
                  sx={{ display: "flex", flexDirection: "row", width: "100%" }}
                >
                  <PaidIcon
                    sx={{
                      marginLeft: 2,
                      color: this.state.pathname == "/transaction" ? "#008ffb" : "#fff",
                    }}
                  />
                  <Box sx={{ width: "100%", marginLeft: "25%" }}>
                    <Link style={{ textDecoration: "none" }}>
                      <Typography
                        sx={{
                          textDecoration: "none",
                          textAlign: "left",
                          fontSize: 12,
                          fontWeight: "500",
                          mt:0.5,
                          color:
                            this.state.pathname == "/transaction" ? "#008ffb" : "#fff",
                            marginLeft: -5,
                        }}
                      >
                        TRANSACTIONS 
                      </Typography>
                    </Link>
                  </Box>
                </Box>
              </Box>




              <Box
                sx={{
                  marginLeft: 0,
                  marginRight: 0,
                  height: 40,
                  backgroundColor:
                  this.state.pathname == "/tickets" ? "#ebebf5" : "#1b75d3",
                  display: "flex",
                  justifyContent: "left",
                  mt: 1,
                  borderRadius: 0,
                  alignItems: "center",
                }}
                onClick={()=>this.props.navigate('/tickets')}
              >
                <Box
                  sx={{ display: "flex", flexDirection: "row", width: "100%" }}
                >
                  <ConfirmationNumberIcon
                    sx={{
                      marginLeft: 2,
                      color: this.state.pathname == "/tickets" ? "#008ffb" : "#fff",
                    }}
                  />
                  <Box sx={{ width: "100%", marginLeft: "25%" }}>
                    <Link style={{ textDecoration: "none" }}>
                      <Typography
                        sx={{
                          textDecoration: "none",
                          textAlign: "left",
                          fontSize: 12,
                          fontWeight: "500",
                          mt:0.5,
                          color:
                            this.state.pathname == "/tickets" ? "#008ffb" : "#fff",
                            marginLeft: -5,
                        }}
                      >
                        TICKETS LIST
                      </Typography>
                    </Link>
                  </Box>
                </Box>
              </Box>





              <Box
                sx={{
                  marginLeft: 0,
                  marginRight: 0,
                  height: 40,
                  backgroundColor:
                  this.state.pathname == "/blog" ? "#ebebf5" : "#1b75d3",
                  display: "flex",
                  justifyContent: "left",
                  mt: 1,
                  borderRadius: 0,
                  alignItems: "center",
                }}
                onClick={()=>this.props.navigate('/blog')}
              >
                <Box
                  sx={{ display: "flex", flexDirection: "row", width: "100%" }}
                >
                  <DynamicFeedIcon
                    sx={{
                      marginLeft: 2,
                      color: this.state.pathname == "/blog" ? "#008ffb" : "#fff",
                    }}
                  />
                  <Box sx={{ width: "100%", marginLeft: "25%" }}>
                    <Link style={{ textDecoration: "none" }}>
                      <Typography
                        sx={{
                          textDecoration: "none",
                          textAlign: "left",
                          fontSize: 12,
                          fontWeight: "500",
                          mt:0.5,
                          color:
                            this.state.pathname == "/blog" ? "#008ffb" : "#fff",
                            marginLeft: -5,
                        }}
                      >
                        BLOGS LIST
                      </Typography>
                    </Link>
                  </Box>
                </Box>
              </Box>







              <Box
                sx={{
                  marginLeft: 0,
                  marginRight: 0,
                  height: 40,
                  backgroundColor:
                  this.state.pathname == "/docs" ? "#ebebf5" : "#1b75d3",
                  display: "flex",
                  justifyContent: "left",
                  mt: 1,
                  borderRadius: 0,
                  alignItems: "center",
                }}
                onClick={()=>this.props.navigate('/docs')}
              >
                <Box
                  sx={{ display: "flex", flexDirection: "row", width: "100%" }}
                >
                  <PictureAsPdfIcon
                    sx={{
                      marginLeft: 2,
                      color: this.state.pathname == "/docs" ? "#008ffb" : "#fff",
                    }}
                  />
                  <Box sx={{ width: "100%", marginLeft: "25%" }}>
                    <Link style={{ textDecoration: "none" }}>
                      <Typography
                        sx={{
                          textDecoration: "none",
                          textAlign: "left",
                          fontSize: 12,
                          fontWeight: "500",
                          mt:0.5,
                          color:
                            this.state.pathname == "/docs" ? "#008ffb" : "#fff",
                            marginLeft: -5,
                        }}
                      >
                       DOCUMENTS SECTION
                      </Typography>
                    </Link>
                  </Box>
                </Box>
              </Box>




              <Box
                sx={{
                  marginLeft: 0,
                  marginRight: 0,
                  height: 40,
                  backgroundColor:
                  this.state.pathname == "/teeest" ? "#ebebf5" : "#1b75d3",
                  display: "flex",
                  justifyContent: "left",
                  mt: 1,
                  borderRadius: 0,
                  alignItems: "center",
                }}
                onClick={()=>this.props.navigate('/login')}
              >
                <Box
                  sx={{ display: "flex", flexDirection: "row", width: "100%" }}
                >
                  <LogoutIcon
                    sx={{
                      marginLeft: 2,
                      color: this.state.pathname == "/eeusers" ? "#008ffb" : "#fff",
                    }}
                  />
                  <Box sx={{ width: "100%", marginLeft: "25%" }}>
                    <Link style={{ textDecoration: "none" }}>
                      <Typography
                        sx={{
                          textDecoration: "none",
                          textAlign: "left",
                          fontSize: 13,
                          fontWeight: "500",
                          mt:0.5,
                          color:
                            this.state.pathname == "/useeers" ? "#008ffb" : "#fff",
                            marginLeft: -5,
                        }}
                      >
                        LOG OUT  
                      </Typography>
                    </Link>
                  </Box>
                </Box>
              </Box>


          

       




              <br />
              <br />
              <br />
            </Paper>
            <br />
          </Box>
        </Box>
      </div>
    );
  }
}

export default Sidebar;

export function Sidebarc(props) {
  const navigate = useNavigate();
  const location = useLocation();
  return <Sidebar location={location} navigate={navigate}></Sidebar>;
}
