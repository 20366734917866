import React, { Component } from "react";
import { Sidebarc } from "../src/Sidebar";
import { Appheaderc } from "./Appheader";
import { Button, Paper, Typography } from "@mui/material";
import { Box, Backdrop, CircularProgress } from "@mui/material";
import Grid from "@mui/material/Grid";
import {
  Tooltip,
  IconButton,
  TableBody,
  Table,
  Modal,
  MenuItem,
  TableContainer,
  TablePagination,
  Divider,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  InputAdornment,
} from "@mui/material";
import base_url from "./baseurl";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import { ToastContainer, toast } from "react-toastify";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Chart from "react-apexcharts";
import "react-toastify/dist/ReactToastify.css";


import SpeedIcon from '@mui/icons-material/Speed';
import SettingsIcon from '@mui/icons-material/Settings';
import BadgeIcon from '@mui/icons-material/Badge';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import PaidIcon from '@mui/icons-material/Paid';
import LogoutIcon from '@mui/icons-material/Logout';






const drawerWidth = 235;
class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      todayLogin: 10,
      totalLoginIn15days: 5,
      totalLoginIn30days: 45,
      allusers: 50,


      login7days : 40,

    
    };
  }

  componentDidMount() {

/*
    fetch(`${base_url.base_url}/daysLoginCount`, {
      headers: {
        "Content-Type": "application/json",
      },
      method: "GET",
    })
      .then((res) => {
        return res.json();
      })
      .then((result) => {
        this.setState({
          todayLogin: result.todayLogin,
          totalLoginIn15days: result.totalLoginIn15days,
          totalLoginIn30days: result.totalLoginIn30days,
          allusers: result.allusers,
          login7days : result.login7days
        });
      });

*/

  }

  render() {
    return (
      <div>
        <Box sx={{ display: "flex" }}>
          <Sidebarc />
          <Box
            sx={{ width: { sm: `calc(100% - ${drawerWidth}px)`, xs: "100%" } }}
          >
            <Box sx={{ p: { xs: 1, sm: 3 }, mt: 6 }}>
              <Typography
                style={{
                  fontWeight: "bold",
                  color: "black",
                  fontSize: 16,
                  marginTop: 5,
                  marginBottom: 5,
                  marginLeft: 10,
                }}
              >
                Dashboard
              </Typography>





<Box>
              <Grid container spacing={{ xs: 1, sm: 2, md: 2 }}>
                <Grid item xs={12} sm={6} md={3}>
                  <Paper
                    elevation={1}
                    sx={{
                      height: 110,
                      backgroundColor: "#008ffb",
                      width: "100%",
                      borderRadius: 2,
                    }}
                  >
                    <Box sx={{ padding: 1.5 }}>
                      <Typography
                        sx={{
                          fontSize: 12,
                          fontWeight: "550",
                          fontFamily: "sans-serif",
                          color: "#fff",
                        }}
                      >
                        TOTAL USERS REGISTRATION
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: 19,
                          fontWeight: "550",
                          fontFamily: "sans-serif",
                          color: "#fff",
                          mt: 1,
                        }}
                      >
                        {this.state.todayLogin}
                      </Typography>

                      <Box
                        sx={{
                          width: "100%",
                          height: 45,
                          display: "flex",
                          justifyContent: "right",
                        }}
                      >
                        <Box
                          sx={{
                            height: 40,
                            width: 40,
                            backgroundColor: "#e2f1ff",
                            borderRadius: 3,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <AccountCircleIcon
                            sx={{ height: 30, width: 30, color: "#008ffb" }}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Paper>
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <Paper
                    elevation={1}
                    sx={{
                      height: 110,
                      backgroundColor: "#008ffb",
                      width: "100%",
                      borderRadius: 2,
                    }}
                  >
                    <Box sx={{ padding: 1.5 }}>
                      <Typography
                        sx={{
                          fontSize: 12,
                          fontWeight: "550",
                          fontFamily: "sans-serif",
                          color: "#fff",
                        }}
                      >
                        TOTAL SUBJECT LISTED
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: 19,
                          fontWeight: "550",
                          fontFamily: "sans-serif",
                          color: "#fff",
                          mt: 1,
                        }}
                      >
                        {this.state.totalLoginIn15days}
                      </Typography>
                      <Box
                        sx={{
                          width: "100%",
                          height: 45,
                          display: "flex",
                          justifyContent: "right",
                        }}
                      >
                        <Box
                          sx={{
                            height: 40,
                            width: 40,
                            backgroundColor: "#e2f1ff",
                            borderRadius: 3,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <AccountCircleIcon
                            sx={{ height: 30, width: 30, color: "#008ffb" }}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Paper>
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <Paper
                    elevation={1}
                    sx={{
                      height: 110,
                      backgroundColor: "#008ffb",
                      width: "100%",
                      borderRadius: 2,
                    }}
                  >
                    <Box sx={{ padding: 1.5 }}>
                      <Typography
                        sx={{
                          fontSize: 12,
                          fontWeight: "550",
                          fontFamily: "sans-serif",
                          color: "#fff",
                        }}
                      >
                        TOTAL NEW TICKET GEN
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: 19,
                          fontWeight: "550",
                          fontFamily: "sans-serif",
                          color: "#fff",
                          mt: 1,
                        }}
                      >
                       {this.state.totalLoginIn30days}
                      </Typography>
                      <Box
                        sx={{
                          width: "100%",
                          height: 45,
                          display: "flex",
                          justifyContent: "right",
                        }}
                      >
                        <Box
                          sx={{
                            height: 40,
                            width: 40,
                            backgroundColor: "#e2f1ff",
                            borderRadius: 3,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <AccountCircleIcon
                            sx={{ height: 30, width: 30, color: "#008ffb" }}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Paper>
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <Paper
                    elevation={1}
                    sx={{
                      height: 110,
                      backgroundColor: "#008ffb",
                      width: "100%",
                      borderRadius: 2,
                    }}
                  >
                    <Box sx={{ padding: 1.5 }}>
                      <Typography
                        sx={{ fontSize: 12, fontWeight: "bold", color: "#fff" }}
                      >
                      TOTAL AMOUNT EARNED                   </Typography>
                      <Typography
                        sx={{
                          fontSize: 19,
                          fontWeight: "550",
                          fontFamily: "sans-serif",
                          color: "#fff",
                          mt: 1,
                        }}
                      >
                       {this.state.allusers}
                      </Typography>
                      <Box
                        sx={{
                          width: "100%",
                          height: 45,
                          display: "flex",
                          justifyContent: "right",
                        }}
                      >
                        <Box
                          sx={{
                            height: 40,
                            width: 40,
                            backgroundColor: "#e2f1ff",
                            borderRadius: 3,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <PaidIcon
                            sx={{ height: 30, width: 30, color: "#008ffb" }}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Paper>
                </Grid>
              </Grid>
</Box>





















            </Box>
          </Box>


          <Box
            sx={{
              display: "flex",
              position: "fixed",
              top: 0,
              left: { xs: 0, sm: 235 },
            }}
          >
            <Appheaderc />
          </Box>

        </Box>
      </div>
    );
  }
}

export default Dashboard;
