import React, { Component } from "react";
import { Sidebarc } from "../src/Sidebar";
import { Appheaderc } from "./Appheader";
import { Button, Paper, Typography } from "@mui/material";
import { Box, Backdrop, CircularProgress } from "@mui/material";
import Grid from "@mui/material/Grid";
import { TextField } from "@mui/material";
import base_url from "./baseurl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styled } from "@mui/material/styles";
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const drawerWidth = 235;
class Documentsection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      privacy_policy: "",
      terms_and_condition: "",
      whatsapp_no: "",
      contact_no: "",
      linked_in: "",
    };
    this.handleChange = this.handleChange.bind();
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  componentDidMount() {
    /*
  fetch(`${base_url.base_url}/retriveSettings`, {
    headers: {
      "content-type": "application/json",
    },
    method: "post",
    body: JSON.stringify({
      id : '670be1dbe9ed547f5543e721'
    }),
  })
    .then((res) => {
      return res.json();
    })
    .then(async (result) => {
      this.setState({
        privacy_policy: result.data.privacy_policy,
        terms_and_condition: result.data.terms_and_condition,
        whatsapp_no: result.data.whatsapp_no,
        contact_no: result.data.contact_no,
        linked_in: result.data.linked_in,
      })
    });
*/
  }

  fieldunfilled = () => {
    toast.info(
      <Typography sx={{ fontSize: 13, fontWeight: "bold" }}>
        Fill All Fields{" "}
      </Typography>,
      {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        icon: "🚀",
        theme: "colored",
      }
    );
  };

  updateData = () => {
    toast.info(
      <Typography sx={{ fontSize: 13, fontWeight: "bold" }}>
        Settings updated{" "}
      </Typography>,
      {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        icon: "🚀",
        theme: "colored",
      }
    );
  };

  updatesettings = () => {
    /*
if(this.state.privacy_policy!== ""&&
  this.state.terms_and_condition!== ""&&
  this.state.whatsapp_no!== ""&&
  this.state.contact_no!== ""&&
  this.state.linked_in!== ""){

   fetch(`${base_url.base_url}/updateSettings`, {
      headers: {
        "content-type": "application/json",
      },
      method: "put",
      body: JSON.stringify({
        privacy_policy: this.state.privacy_policy,
        terms_and_condition: this.state.terms_and_condition,
        whatsapp_no: this.state.whatsapp_no,
        contact_no: this.state.contact_no,
        linked_in: this.state.linked_in,
        id : '670be1dbe9ed547f5543e721'
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then(async (result) => {
        this.updateData();
      });

  }else{

this.fieldunfilled()

  }
 */
  };

  render() {
    return (
      <div>
        <Box sx={{ display: "flex" }}>
          <Sidebarc />
          <Box
            sx={{ width: { sm: `calc(100% - ${drawerWidth}px)`, xs: "100%" } }}
          >
            <Box sx={{ p: { xs: 1, sm: 3 }, mt: 6 }}>
              <Typography
                style={{
                  fontWeight: "bold",
                  color: "black",
                  fontSize: 16,
                  marginTop: 5,
                  marginBottom: 5,
                  marginLeft: 10,
                }}
              >
                Add PDF docs
              </Typography>

              <Box>
                <Paper
                  elevation={0}
                  style={{ minHeight: 150, width: "100%", color: "#fff" }}
                >
                  <Box sx={{ padding: 3 }}>
                    <Paper
                      elevation={1}
                      sx={{
                        height: 40,
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography sx={{ ml: 3, fontSize: 13 }}>
                        samplepdfdayta.pdf
                      </Typography>
                      <Button
                        size="small"
                        sx={{ mr: 2, backgroundColor: "red" }}
                      >
                        Remove
                      </Button>
                    </Paper>
                  </Box>


                  <Button
                    size="small"
                    sx={{ml:3}}
                    component="label"
                    role={undefined}
                    variant="contained"
                    tabIndex={-1}
                    startIcon={<CloudUploadIcon />}
                  >
                    Upload PDF
                    <VisuallyHiddenInput
                      type="file"
                      onChange={(event) => console.log(event.target.files)}
                      multiple
                    />
                  </Button>



                </Paper>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              position: "fixed",
              top: 0,
              left: { xs: 0, sm: 235 },
            }}
          >
            <Appheaderc />
          </Box>
        </Box>

        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    );
  }
}

export default Documentsection;
