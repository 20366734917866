import React, { Component } from "react";
import { Sidebarc } from "../src/Sidebar";
import { Appheaderc } from "./Appheader";
import { Button, Paper, Typography } from "@mui/material";
import { Box, Backdrop, CircularProgress } from "@mui/material";
import Grid from "@mui/material/Grid";
import {
  TextField,
} from "@mui/material";
import base_url from "./baseurl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const drawerWidth = 235;
class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      privacy_policy: "",
      terms_and_condition: "",
      whatsapp_no: "",
      contact_no: "",
      linked_in: "",

    };
    this.handleChange = this.handleChange.bind();
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };



componentDidMount(){
/*
  fetch(`${base_url.base_url}/retriveSettings`, {
    headers: {
      "content-type": "application/json",
    },
    method: "post",
    body: JSON.stringify({
      id : '670be1dbe9ed547f5543e721'
    }),
  })
    .then((res) => {
      return res.json();
    })
    .then(async (result) => {
      this.setState({
        privacy_policy: result.data.privacy_policy,
        terms_and_condition: result.data.terms_and_condition,
        whatsapp_no: result.data.whatsapp_no,
        contact_no: result.data.contact_no,
        linked_in: result.data.linked_in,
      })
    });
*/
}



  fieldunfilled = () => {
    toast.info(
      <Typography sx={{ fontSize: 13, fontWeight: "bold" }}>
        Fill All Fields{" "}
      </Typography>,
      {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        icon: "🚀",
        theme: "colored",
      }
    );
  };


  updateData = () => {
    toast.info(
      <Typography sx={{ fontSize: 13, fontWeight: "bold" }}>
        Settings updated{" "}
      </Typography>,
      {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        icon: "🚀",
        theme: "colored",
      }
    );
  };



  updatesettings = () => {
    /*
if(this.state.privacy_policy!== ""&&
  this.state.terms_and_condition!== ""&&
  this.state.whatsapp_no!== ""&&
  this.state.contact_no!== ""&&
  this.state.linked_in!== ""){

   fetch(`${base_url.base_url}/updateSettings`, {
      headers: {
        "content-type": "application/json",
      },
      method: "put",
      body: JSON.stringify({
        privacy_policy: this.state.privacy_policy,
        terms_and_condition: this.state.terms_and_condition,
        whatsapp_no: this.state.whatsapp_no,
        contact_no: this.state.contact_no,
        linked_in: this.state.linked_in,
        id : '670be1dbe9ed547f5543e721'
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then(async (result) => {
        this.updateData();
      });

  }else{

this.fieldunfilled()

  }
 */
  };



  render() {
    return (
      <div>
        <Box sx={{ display: "flex" }}>
          <Sidebarc />
          <Box
            sx={{ width: { sm: `calc(100% - ${drawerWidth}px)`, xs: "100%" } }}
          >
            <Box sx={{ p: { xs: 1, sm: 3 }, mt: 6 }}>
              <Typography
                style={{
                  fontWeight: "bold",
                  color: "black",
                  fontSize: 16,
                  marginTop: 5,
                  marginBottom: 5,
                  marginLeft: 10,
                }}
              >
               Account Detail Setting
              </Typography>

              <Box>
                <Paper
                  elevation={0}
                  style={{ minHeight: 150, width: "100%", color: "#fff" }}
                >
                  <Box sx={{ padding: 3 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} md={3}>
                        <Typography sx={{ fontSize: 12, color: "black" }}>
                         Email id
                        </Typography>
                        <TextField
                          size="small"
                          fullWidth
                          InputProps={{ style: { fontSize: 12 ,fontWeight:'bold'} }}
                          name="privacy_policy"
                          value={this.state.privacy_policy}
                          onChange={this.handleChange}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <Typography sx={{ fontSize: 12, color: "black" }}>
                         Password
                        </Typography>
                        <TextField
                          size="small"
                          fullWidth
                          InputProps={{ style: { fontSize: 12,fontWeight:'bold' } }}
                          name="terms_and_condition"
                          value={this.state.terms_and_condition}
                          onChange={this.handleChange}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <Typography sx={{ fontSize: 12, color: "black" }}>
                          Whatsapp number
                        </Typography>
                        <TextField
                          size="small"
                          fullWidth
                          InputProps={{ style: { fontSize: 12 ,fontWeight:'bold'} }}
                          name="whatsapp_no"
                          value={this.state.whatsapp_no}
                          onChange={this.handleChange}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <Typography sx={{ fontSize: 12, color: "black" }}>
                          Contact no
                        </Typography>
                        <TextField
                          size="small"
                          fullWidth
                          InputProps={{ style: { fontSize: 12 , fontWeight:'bold'} }}
                          name="contact_no"
                          value={this.state.contact_no}
                          onChange={this.handleChange}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <Typography sx={{ fontSize: 12, color: "black" }}>
                          Linkedin(url)
                        </Typography>
                        <TextField
                          size="small"
                          fullWidth
                          InputProps={{ style: { fontSize: 12 , fontWeight:'bold'} }}
                          name="linked_in"
                          value={this.state.linked_in}
                          onChange={this.handleChange}
                        />
                      </Grid>
                    </Grid>
                  </Box>

                  <Button
                  onClick={this.updatesettings}
                    size="small"
                    style={{
                      height: 30,
                      textTransform: "none",
                      marginLeft: 25,
                      marginBottom: 10,
                    }}
                    variant="contained"
                  >
                    Update Details
                  </Button>
                </Paper>
              </Box>
            </Box>
          </Box>



          <Box
            sx={{
              display: "flex",
              position: "fixed",
              top: 0,
              left: { xs: 0, sm: 235 },
            }}
          >
            <Appheaderc />
          </Box>
        </Box>


        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    );
  }
}

export default Settings;
