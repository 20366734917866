import React, { Component } from "react";
import { Sidebarc } from "../src/Sidebar";
import { Appheaderc } from "./Appheader";
import { Autocomplete, Button, Divider, Paper, Typography,Chip, Modal,MenuItem } from "@mui/material";
import { Box, Backdrop, CircularProgress } from "@mui/material";
import Grid from "@mui/material/Grid";
import {
  TextField,
} from "@mui/material";
import base_url from "./baseurl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";






const drawerWidth = 235;
class Questionpaper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      option_for_question:[],

      is_option_add : false,
      option : ""

    };
    this.handleChange = this.handleChange.bind();
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };



componentDidMount(){
/*
  fetch(`${base_url.base_url}/retriveSettings`, {
    headers: {
      "content-type": "application/json",
    },
    method: "post",
    body: JSON.stringify({
      id : '670be1dbe9ed547f5543e721'
    }),
  })
    .then((res) => {
      return res.json();
    })
    .then(async (result) => {
      this.setState({
        privacy_policy: result.data.privacy_policy,
        terms_and_condition: result.data.terms_and_condition,
        whatsapp_no: result.data.whatsapp_no,
        contact_no: result.data.contact_no,
        linked_in: result.data.linked_in,
      })
    });
*/
}



  fieldunfilled = () => {
    toast.info(
      <Typography sx={{ fontSize: 13, fontWeight: "bold" }}>
        Fill All Fields{" "}
      </Typography>,
      {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        icon: "🚀",
        theme: "colored",
      }
    );
  };


  updateData = () => {
    toast.info(
      <Typography sx={{ fontSize: 13, fontWeight: "bold" }}>
        Settings updated{" "}
      </Typography>,
      {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        icon: "🚀",
        theme: "colored",
      }
    );
  };



  updatesettings = () => {
    /*
if(this.state.privacy_policy!== ""&&
  this.state.terms_and_condition!== ""&&
  this.state.whatsapp_no!== ""&&
  this.state.contact_no!== ""&&
  this.state.linked_in!== ""){

   fetch(`${base_url.base_url}/updateSettings`, {
      headers: {
        "content-type": "application/json",
      },
      method: "put",
      body: JSON.stringify({
        privacy_policy: this.state.privacy_policy,
        terms_and_condition: this.state.terms_and_condition,
        whatsapp_no: this.state.whatsapp_no,
        contact_no: this.state.contact_no,
        linked_in: this.state.linked_in,
        id : '670be1dbe9ed547f5543e721'
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then(async (result) => {
        this.updateData();
      });

  }else{

this.fieldunfilled()

  }
 */
  };



  render() {
    return (
      <div>
        <Box sx={{ display: "flex" }}>
          <Sidebarc />
          <Box
            sx={{ width: { sm: `calc(100% - ${drawerWidth}px)`, xs: "100%" } }}
          >


            <Box sx={{ p: { xs: 1, sm: 3 },mt:10}}>
              <Box sx={{ backgroundColor: "#fff",pl:{xs:3,sm:10},pr:{xs:3,sm:10}}}>
              <br/>
                <Typography sx={{fontsize:15,fontWeight:'bold',mb:1,mt:1,borderBottomColor:'green',borderWidth:1}}>ADD QUESTION LIST HERE</Typography>
                 <Divider sx={{backgroundColor:'blue'}}/>


                <Typography sx={{fontSize:12,fontWeight:'bold',color:'black',mb:0.5,mt:2}}>Your question in MARATHI language*</Typography>
                <TextField size='small' placeholder="Your Question in marathi" sx={{mb:2}} fullWidth/>

                <Typography sx={{fontSize:12,fontWeight:'bold',color:'black',mb:0.5}}>Your question in English language*</Typography>
                <TextField size='small' placeholder="Your Question in english" sx={{mb:2}} fullWidth/>


                 <Divider/>

      <Button onClick={()=>this.setState({is_option_add:true})} size="small" sx={{mt:2}} variant='contained'>
       Add options
      </Button>

        <Typography sx={{fontSize:12,fontWeight:'600',padding:0.2,mt:3,display:'flex',flexDirection:'row'}}>Options List<Typography sx={{color:'red'}}>*</Typography></Typography>
        <Autocomplete
        sx={{fontSize:10,mb:2}}
        multiple
        ListboxProps={{
          sx: { fontSize:12,fontWeight:'600',maxHeight:150 },
          }}
        id="tags-filled"
        onChange={(e,data)=>{this.setState({option_for_question:data})}}
        value={this.state.option_for_question}
        options={this.state.option_for_question.map((option) => option )}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip sx={{height:26,fontSize:12,fontWeight:'600',backgroundColor:'#eff0f0'}} variant="outlined" label={option} {...getTagProps({ index })} />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant='outlined'
            size='small'
            fullWidth
          />
        )}
         />


<Divider/>

<Typography sx={{fontSize:12,fontWeight:'600',padding:0.2,mt:3,display:'flex',flexDirection:'row'}}>Select correct<Typography sx={{color:'red'}}>*</Typography></Typography>
< TextField select onChange={this.handleChange} SelectProps={{MenuProps:{sx:{height:300}}}}  InputProps={{sx:{fontSize:12,fontWeight:'600',textTransform:'capitalize'}}}  value={this.state.client_name} name="client_name" fullWidth size='small'>
{
 this.state.option_for_question.map((e)=>(
<MenuItem key={e} sx={{fontSize:12,fontWeight:'600'}} onClick={()=>{
  this.setState({client_id:e.client_id,client_name:e.client_name,department_id:"",department_name:"",campaign_manager:"",campaign_manager_id:"",contactList:[],end_client:"",end_client_id:""},()=>{
 
  })

  }} value={e}>
{e}
</MenuItem>
    ))
  }
</TextField>

<br/>
<br/>

<Button size="small"  sx={{mt:2,backgroundColor:'red'}} variant='contained'>
   Add question
</Button>

<br/>
<br/>
              </Box>
            </Box>
          </Box>



          <Box
            sx={{
              display: "flex",
              position: "fixed",
              top: 0,
              left: { xs: 0, sm: 235 },
            }}
          >
            <Appheaderc />
          </Box>
        </Box>





<Box> 
<Modal
  open={this.state.is_option_add}
  onClose={this.handleClose}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box sx={{display:'flex',justifyContent:'center',alignItems:'center',height:'100%',width:'100%'}}>
<Paper sx={{width:{xs:'90%',sm:'90%',md:'60%',lg:'40%'},backgroundColor:'white',borderRadius:2}}>

<Box sx={{width:'100%',display:'flex',justifyContent:'right'}}>
<Paper onClick={()=>this.setState({is_option_add:false})} elevation={5} sx={{height:30,width:30,borderRadius:1,backgroundColor:'#fff',display:'flex',justifyContent:'center',alignItems:'center',mr:-1,mt:-1}}>
<CloseIcon sx={{height:20,width:20,color:'#2486bb'}}/>
</Paper>
</Box>

<Typography sx={{fontSize:18,fontWeight:'600',paddingLeft:{xs:2,sm:4},mb:2}}>Add option for question</Typography>
<Box sx={{paddingLeft:{xs:2,sm:4},paddingRight:{xs:2,sm:4},minHeight:'10vh'}}>

<Typography sx={{fontSize:12,fontWeight:'600',padding:0.2,display:'flex',flexDirection:'row'}}>Option<Typography sx={{color:'red'}}>*</Typography></Typography>
< TextField onChange={this.handleChange} value={this.state.option} name='option'  InputProps={{sx:{fontSize:12,fontWeight:'600',textTransform:'capitalize'}}}  fullWidth size='small'/>
<br/>
<br/>
<Button variant='contained' onClick={()=>{
if(this.state.option==""){
  alert("Not valid")
}else{
 this.setState(prevState => ({
  option_for_question: [...prevState.option_for_question,this.state.option] 
}),()=>{
  this.setState({is_option_add:false,option:""})
}) 
}
}} size='small' sx={{textTransform:'none'}}>Save</Button>
<br/>
<br/>
</Box>
</Paper>
</Box>
</Modal>
</Box>



        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    );
  }
}

export default Questionpaper;





const country= ['None'];
