import React, { Component } from "react";
import { Sidebarc } from "../src/Sidebar";
import { Appheaderc } from "./Appheader";
import { Button, Paper, Typography } from "@mui/material";
import { Box, Backdrop, CircularProgress } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import DriveFileMoveIcon from "@mui/icons-material/DriveFileMove";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment"
import InfoIcon from '@mui/icons-material/Info';

import {
  Tooltip,
  IconButton,
  TableBody,
  Table,
  Modal,
  MenuItem,
  TableContainer,
  TablePagination,
  Divider,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  InputAdornment,
} from "@mui/material";
import base_url from "./baseurl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const drawerWidth = 240;

class UserList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      email: "",
      phone: "",
      password: "",


      id: "",
      is_addusers: false,
      is_editusers: false,

      userList: [],

      page: 0,
      rowsPerPage: 10,
      counts: 0,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  componentDidMount() {
    
  }

  handleChangePage = (event, newPage) => {
   
 
  };



  handleChangeRowsPerPage = (event) => {
 
  };

  autoUpdateFunction = () => {

  };








  succes = () => {
    toast.info(
      <Typography sx={{ fontSize: 13, fontWeight: "bold" }}>
        Employee Successfully Added
      </Typography>,
      {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        icon: "🚀",
        theme: "colored",
      }
    );
  };

  fieldunfilled = () => {
    toast.info(
      <Typography sx={{ fontSize: 13, fontWeight: "bold" }}>
        Fill All Fields{" "}
      </Typography>,
      {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        icon: "🚀",
        theme: "colored",
      }
    );
  };

  fieldsdeleted = () => {
    toast.info(
      <Typography sx={{ fontSize: 13, fontWeight: "bold" }}>
        User deleted{" "}
      </Typography>,
      {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        icon: "🚀",
        theme: "colored",
      }
    );
  };

  empupdated = () => {
    toast.info(
      <Typography sx={{ fontSize: 13, fontWeight: "bold" }}>
        User updated{" "}
      </Typography>,
      {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        icon: "🚀",
        theme: "colored",
      }
    );
  };

  userExists = () => {
    toast.error(
      <Typography sx={{ fontSize: 13, fontWeight: "bold" }}>
        User Exist{" "}
      </Typography>,
      {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        icon: "🚀",
        theme: "colored",
      }
    );
  };

  render() {
    return (
      <div>
        <Box sx={{ display: "flex" }}>
          <Sidebarc />
          <Box
            sx={{ width: { sm: `calc(100% - ${drawerWidth}px)`, xs: "100%" } }}
          >
            <Box sx={{ p: { xs: 1, sm: 3 }, mt: 6 }}>
              <Typography
                style={{
                  fontWeight: "bold",
                  color: "black",
                  fontSize: 16,
                  marginTop: 5,
                  marginBottom: 5,
                  marginLeft: 10,
                }}
              >
                Users List
              </Typography>

              <Paper
                elevation={0}
                sx={{
                  height: 50,
                  width: "100%",
                  backgroundColor: "#fff",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "left",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: 17,
                      fontWeight: "500",
                      paddingLeft: { xs: 1, sm: 2, md: 3 },
                      color: "#666666",
                    }}
                  >
                    Users List
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "right",
                    alignItems: "center",
                    mr: { xs: 1, sm: 2, md: 3 },
                  }}
                >
                  <Button
                    onClick={() => this.setState({ is_addusers: true })}
                    sx={{
                      textTransform: "none",
                      height: 30,
                      backgroundColor: "#008ffb",
                      fontWeight: "600",
                    }}
                    disableElevation
                    variant="contained"
                    startIcon={<AddIcon sx={{ color: "#fff" }} />}
                  >
                    Add Users
                  </Button>
                </Box>
              </Paper>

              <Paper elevation={0} sx={{ width: "100%", minHeight: 200, mt: 2 }}>
                <Box sx={{ mt: 0, padding: 2 }}>
                  <TableContainer component={Box}>
                    <Table
                      sx={{ minWidth: 1220 }}
                      size="small"
                      aria-label="a dense table"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell
                            align="left"
                            sx={{
                              fontSize: 14,
                              fontWeight: "600",
                              color: "#919191",
                            }}
                          >
                            sr no
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{
                              fontSize: 14,
                              fontWeight: "600",
                              color: "#919191",
                            }}
                          >
                            Name
                          </TableCell>
                            
                          <TableCell
                            align="left"
                            sx={{
                              fontSize: 14,
                              fontWeight: "600",
                              color: "#919191",
                            }}
                          >
                            Created at
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{
                              fontSize: 14,
                              fontWeight: "600",
                              color: "#919191",
                            }}
                          >
                            Phone
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{
                              fontSize: 14,
                              fontWeight: "600",
                              color: "#919191",
                            }}
                          >
                            Email
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{
                              fontSize: 14,
                              fontWeight: "600",
                              color: "#919191",
                            }}
                          >
                            Password
                          </TableCell>
                          <TableCell
                            align="right"
                            sx={{
                              fontSize: 14,
                              fontWeight: "600",
                              color: "#919191",
                            }}
                          >
                            Action
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {[1,2,3,4].map((row,i) => (
                          <TableRow
                            key={row._id}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },backgroundColor:i%2?'#f4f4f4':'#fff'
                            }}
                          >
                            <TableCell
                              component="th"
                              scope="row"
                              sx={{ color: "#42526e", fontSize: 13 }}
                            >
                              <Button
                                variant="contained"
                                disableElevation
                                style={{
                                  height: 20,
                                  fontSize: 11,
                                  fontWeight: "600",
                                  color: "#fff",
                                }}
                              >
                                NK001
                              </Button>
                            </TableCell>
                        
                            <TableCell align="left" sx={{ color: "#42526e",fontSize:12 }}>
                              Nitish k
                            </TableCell>  
                            
                              <TableCell align="left" sx={{ color: "#42526e",fontSize:12 }}>
                           {moment().format('YYYY-MM-DD')}
                            </TableCell>
                            <TableCell align="left" sx={{ color: "#42526e" ,fontSize:12}}>
                             9139549561
                            </TableCell>
                            <TableCell align="left" sx={{ color: "#42526e" ,fontSize:12}}>
                              7292961263@gmail.com
                            </TableCell>
                            <TableCell align="left" sx={{ color: "#42526e" ,fontSize:12}}>
                            123456nk
                            </TableCell>
                            <TableCell align="right">
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "right",
                                }}
                              >
                                <Tooltip title="Edit">
                                  <IconButton
                                    onClick={() => {
                                      this.setState({
                                        name: row.name,
                                        email: row.email,
                                        phone: row.phone,
                                        password: row.password,
                                        id: row._id,
                                        is_editusers: true,
                                      });
                                    }}
                                    size="small"
                                  >
                                    <DriveFileRenameOutlineIcon
                                      sx={{
                                        color: "#8787c5",
                                        height: 15,
                                        width: 15,
                                      }}
                                    />
                                  </IconButton>
                                </Tooltip>

                                <Tooltip title="Details">
                                  <IconButton
                                    size="small"
                                  >
                                 
                                    <InfoIcon
                                      sx={{
                                        color: "#4d95dc",
                                        height: 15,
                                        width: 15,
                                      }}
                                    />
                                  </IconButton>
                                </Tooltip>


                                <Tooltip title="Delete">
                                  <IconButton
                                    onClick={() => {
                                      if (window.confirm("Do you really want to delete?")) {
                                      
                                      

                                      }
                                    }}
                                    size="small"
                                  >
                                    <DeleteForeverIcon
                                      sx={{
                                        color: "#f29494",
                                        height: 15,
                                        width: 15,
                                      }}
                                    />
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Divider />
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={this.state.counts}
                    rowsPerPage={this.state.rowsPerPage}
                    page={this.state.page}
                    onPageChange={this.handleChangePage}
                    onRowsPerPageChange={this.handleChangeRowsPerPage}
                  />
                </Box>
              </Paper>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              position: "fixed",
              top: 0,
              left: { xs: 0, sm: 240 },
            }}
          >
            <Appheaderc />
          </Box>
        </Box>

        {
          //////// add userDtata data
        }

        <Box>
          <Modal
            open={this.state.is_addusers}
            onClose={this.handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                width: "100%",
              }}
            >
              <Paper
                sx={{
                  width: { xs: "90%", sm: "90%", md: "30%", lg: "30%" },
                  height: "90vh",
                  backgroundColor: "white",
                  borderRadius: 2,
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "right",
                  }}
                >
                  <Paper
                    onClick={() =>
                      this.setState({
                        is_addusers: false,
                        name: "",
                        email: "",
                        phone: "",
                        password: "",
                        id: "",
                      })
                    }
                    elevation={5}
                    sx={{
                      height: 30,
                      width: 30,
                      borderRadius: 1,
                      backgroundColor: "#fff",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      mr: -1,
                      mt: -1,
                    }}
                  >
                    <CloseIcon
                      sx={{ height: 20, width: 20, color: "#2486bb" }}
                    />
                  </Paper>
                </Box>

                <Typography
                  sx={{
                    fontSize: 18,
                    fontWeight: "600",
                    paddingLeft: { xs: 2, sm: 4 },
                    mb: 2,
                  }}
                >
                  Register User
                </Typography>
                <Box
                  sx={{
                    overflowY: "scroll",
                    "&::-webkit-scrollbar": { width: "5px", borderRadius: 10 },
                  }}
                >
                  <Box
                    sx={{
                      paddingLeft: { xs: 2, sm: 4 },
                      paddingRight: { xs: 2, sm: 4 },
                      height: "76vh",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        fontWeight: "600",
                        padding: 0.2,
                        mt: 1,
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      {" "}
                      Name<Typography sx={{ color: "red" }}>*</Typography>
                    </Typography>
                    <TextField
                      type="text"
                      InputProps={{ sx: { fontSize: 12, fontWeight: "600" } }}
                      name="name"
                      value={this.state.name}
                      onChange={this.handleChange}
                      fullWidth
                      size="small"
                    />

                    <Typography
                      sx={{
                        fontSize: 12,
                        fontWeight: "600",
                        padding: 0.2,
                        mt: 1,
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      Email<Typography sx={{ color: "red" }}>*</Typography>
                    </Typography>
                    <TextField
                      type="text"
                      InputProps={{ sx: { fontSize: 12, fontWeight: "600" } }}
                      name="email"
                      value={this.state.email}
                      onChange={this.handleChange}
                      fullWidth
                      size="small"
                    />

                    <Typography
                      sx={{
                        fontSize: 12,
                        fontWeight: "600",
                        padding: 0.2,
                        mt: 1,
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      mob no<Typography sx={{ color: "red" }}>*</Typography>
                    </Typography>
                    <TextField
                      type="text"
                      InputProps={{ sx: { fontSize: 12, fontWeight: "600" } }}
                      name="phone"
                      onChange={this.handleChange}
                      value={this.state.phone}
                      fullWidth
                      size="small"
                    />

                    <Typography
                      sx={{
                        fontSize: 12,
                        fontWeight: "600",
                        padding: 0.2,
                        mt: 1,
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      password<Typography sx={{ color: "red" }}>*</Typography>
                    </Typography>
                    <TextField
                      type="text"
                      InputProps={{ sx: { fontSize: 12, fontWeight: "600" } }}
                      onChange={this.handleChange}
                      name="password"
                      value={this.state.password}
                      fullWidth
                      size="small"
                    />

                    <Button
                      onClick={this.addUserData}
                      variant="contained"
                      disableElevation
                      size="small"
                      sx={{
                        backgroundColor: "#2486bb",
                        mt: 2,
                        textTransform: "none",
                      }}
                    >
                      save data
                    </Button>
                  </Box>
                </Box>
              </Paper>
            </Box>
          </Modal>
        </Box>

        {
          ///// edit section
        }

        <Box>
          <Modal
            open={this.state.is_editusers}
            onClose={this.handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                width: "100%",
              }}
            >
              <Paper
                sx={{
                  width: { xs: "90%", sm: "90%", md: "30%", lg: "30%" },
                  height: "90vh",
                  backgroundColor: "white",
                  borderRadius: 2,
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "right",
                  }}
                >
                  <Paper
                    onClick={() =>
                      this.setState({
                        is_editusers: false,
                        name: "",
                        email: "",
                        phone: "",
                        password: "",
                        id: "",
                      })
                    }
                    elevation={5}
                    sx={{
                      height: 30,
                      width: 30,
                      borderRadius: 1,
                      backgroundColor: "#fff",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      mr: -1,
                      mt: -1,
                    }}
                  >
                    <CloseIcon
                      sx={{ height: 20, width: 20, color: "#2486bb" }}
                    />
                  </Paper>
                </Box>

                <Typography
                  sx={{
                    fontSize: 18,
                    fontWeight: "600",
                    paddingLeft: { xs: 2, sm: 4 },
                    mb: 2,
                  }}
                >
                  Edit Users
                </Typography>
                <Box
                  sx={{
                    overflowY: "scroll",
                    "&::-webkit-scrollbar": { width: "5px", borderRadius: 10 },
                  }}
                >
                  <Box
                    sx={{
                      paddingLeft: { xs: 2, sm: 4 },
                      paddingRight: { xs: 2, sm: 4 },
                      height: "76vh",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        fontWeight: "600",
                        padding: 0.2,
                        mt: 1,
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      {" "}
                      Name<Typography sx={{ color: "red" }}>*</Typography>
                    </Typography>
                    <TextField
                      type="text"
                      InputProps={{ sx: { fontSize: 12, fontWeight: "600" } }}
                      name="name"
                      value={this.state.name}
                      onChange={this.handleChange}
                      fullWidth
                      size="small"
                    />

                    <Typography
                      sx={{
                        fontSize: 12,
                        fontWeight: "600",
                        padding: 0.2,
                        mt: 1,
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      Email<Typography sx={{ color: "red" }}>*</Typography>
                    </Typography>
                    <TextField
                      type="text"
                      InputProps={{ sx: { fontSize: 12, fontWeight: "600" } }}
                      name="email"
                      value={this.state.email}
                      onChange={this.handleChange}
                      fullWidth
                      size="small"
                    />

                    <Typography
                      sx={{
                        fontSize: 12,
                        fontWeight: "600",
                        padding: 0.2,
                        mt: 1,
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      mob no<Typography sx={{ color: "red" }}>*</Typography>
                    </Typography>
                    <TextField
                      type="text"
                      InputProps={{ sx: { fontSize: 12, fontWeight: "600" } }}
                      name="phone"
                      onChange={this.handleChange}
                      value={this.state.phone}
                      fullWidth
                      size="small"
                    />

                    <Typography
                      sx={{
                        fontSize: 12,
                        fontWeight: "600",
                        padding: 0.2,
                        mt: 1,
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      Password<Typography sx={{ color: "red" }}>*</Typography>
                    </Typography>
                    <TextField
                      type="text"
                      InputProps={{ sx: { fontSize: 12, fontWeight: "600" } }}
                      onChange={this.handleChange}
                      name="Password"
                      value={this.state.password}
                      fullWidth
                      size="small"
                    />

                    <Button
                      onClick={this.updateUserData}
                      variant="contained"
                      disableElevation
                      size="small"
                      sx={{
                        backgroundColor: "#2486bb",
                        mt: 2,
                        textTransform: "none",
                      }}
                    >
                      update save
                    </Button>
                  </Box>
                </Box>
              </Paper>
            </Box>
          </Modal>
        </Box>

        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    );
  }
}

export default UserList;


