import logo from './logo.svg';
import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Dashboard from './Dashboard';
import Login from '../src/Login'
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import UserList from './UserList';
import Payments from './Payments';
import Tickets from './Tickets'
import Settings from './Settings'
import BlogsSection from './BlogsSection';
import Questionpaper from './Questionpaper';
import Documentsection from './Documentsection';
import Subject from './Subject';

function App() {
  return (
    <Box sx={{backgroundColor:'#f8f9ff'}}>
    <React.StrictMode>
    <RouterProvider router={createBrowserRouter([
      
    {
      path: "/" ,
      element:<Login/>,
    },
    {
    path:"/dashboard",
    element:<Dashboard/>,
    },
    {
      path:"/user",
      element:<UserList/>,
      },
      {
        path:"/transaction",
        element:<Payments/>,
        },
        
        {
          path:"/tickets",
          element:<Tickets/>,
          },
          {
            path:"/settings",
            element:<Settings/>,
            },
            {
              path:"/blog",
              element:<BlogsSection/>,
              },
              {
                path:"/question_paper",
                element:<Questionpaper/>,
                },
                {
                  path:"/docs",
                  element:<Documentsection/>,
                  },
                  {
                    path:"/subject",
                    element:<Subject/>,
                    },
 
])} />

</React.StrictMode>
</Box>

  );
}

export default App;
