import React, { Component } from "react";
import { Sidebarc } from "../src/Sidebar";
import { Appheaderc } from "./Appheader";
import { Button, Paper, Typography ,MenuItem,Divider} from "@mui/material";
import { Box, Backdrop, CircularProgress } from "@mui/material";
import Grid from "@mui/material/Grid";
import {
  TextField,
} from "@mui/material";
import base_url from "./baseurl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Editor from "./Editor";

const drawerWidth = 235;




class BlogsSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      privacy_policy: "",
      terms_and_condition: "",
      whatsapp_no: "",
      contact_no: "",
      linked_in: "",

    };
    this.handleChange = this.handleChange.bind();
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };



componentDidMount(){
/*
  fetch(`${base_url.base_url}/retriveSettings`, {
    headers: {
      "content-type": "application/json",
    },
    method: "post",
    body: JSON.stringify({
      id : '670be1dbe9ed547f5543e721'
    }),
  })
    .then((res) => {
      return res.json();
    })
    .then(async (result) => {
      this.setState({
        privacy_policy: result.data.privacy_policy,
        terms_and_condition: result.data.terms_and_condition,
        whatsapp_no: result.data.whatsapp_no,
        contact_no: result.data.contact_no,
        linked_in: result.data.linked_in,
      })
    });
*/
}



  fieldunfilled = () => {
    toast.info(
      <Typography sx={{ fontSize: 13, fontWeight: "bold" }}>
        Fill All Fields{" "}
      </Typography>,
      {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        icon: "🚀",
        theme: "colored",
      }
    );
  };


  updateData = () => {
    toast.info(
      <Typography sx={{ fontSize: 13, fontWeight: "bold" }}>
        Settings updated{" "}
      </Typography>,
      {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        icon: "🚀",
        theme: "colored",
      }
    );
  };



  updatesettings = () => {
    /*
if(this.state.privacy_policy!== ""&&
  this.state.terms_and_condition!== ""&&
  this.state.whatsapp_no!== ""&&
  this.state.contact_no!== ""&&
  this.state.linked_in!== ""){

   fetch(`${base_url.base_url}/updateSettings`, {
      headers: {
        "content-type": "application/json",
      },
      method: "put",
      body: JSON.stringify({
        privacy_policy: this.state.privacy_policy,
        terms_and_condition: this.state.terms_and_condition,
        whatsapp_no: this.state.whatsapp_no,
        contact_no: this.state.contact_no,
        linked_in: this.state.linked_in,
        id : '670be1dbe9ed547f5543e721'
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then(async (result) => {
        this.updateData();
      });

  }else{

this.fieldunfilled()

  }
 */
  };



  render() {
    return (
      <div>
        <Box sx={{ display: "flex" }}>
          <Sidebarc />
          <Box
            sx={{ width: { sm: `calc(100% - ${drawerWidth}px)`, xs: "100%" } }}
          >
        
         <Box sx={{ p: { xs: 1, sm: 3 },mt:10}}>
              <Box sx={{ backgroundColor: "#fff",pl:{xs:3,sm:10},pr:{xs:3,sm:10}}}>
              <br/>
                <Typography sx={{fontsize:15,fontWeight:'bold',mb:1,mt:1,borderBottomColor:'green',borderWidth:1}}>WRITE BLOGS HERE</Typography>
                 <Divider sx={{backgroundColor:'blue'}}/>


                <Typography sx={{fontSize:12,fontWeight:'bold',color:'black',mb:0.5,mt:2}}>Headings*</Typography>
                <TextField size='small' placeholder="Headings type here" sx={{mb:1}} fullWidth/>


                <Typography sx={{fontSize:12,fontWeight:'600',padding:0.2,mt:0.5,display:'flex',flexDirection:'row'}}>Select subjects<Typography sx={{color:'red'}}>*</Typography></Typography>
                < TextField select onChange={this.handleChange} SelectProps={{MenuProps:{sx:{height:300}}}}  InputProps={{sx:{fontSize:12,fontWeight:'600',textTransform:'capitalize'}}}  value={this.state.client_name} name="client_name" fullWidth size='small'>
{
 [].map((e)=>(
<MenuItem key={e} sx={{fontSize:12,fontWeight:'600'}} onClick={()=>{
  this.setState({client_id:e.client_id,client_name:e.client_name,department_id:"",department_name:"",campaign_manager:"",campaign_manager_id:"",contactList:[],end_client:"",end_client_id:""},()=>{
 
  })

  }} value={e}>
{e}
</MenuItem>
    ))
  }
                </TextField>


                <Typography sx={{fontSize:12,fontWeight:'bold',color:'black',mb:0.5,mt:1.5}}> Subject heads*</Typography>
                <TextField size='small' placeholder="SUbject headings" sx={{mb:2}} fullWidth/>


                <Typography sx={{fontSize:12,fontWeight:'bold',color:'black',mb:0.5,mt:1.5}}>Write your blogs here*</Typography>
                <Editor/>


<br/>
<br/>

<Button size="small"  sx={{mt:2,backgroundColor:'red'}} variant='contained'>
   Add blogs
</Button>

<br/>
<br/>
              </Box>
            </Box>

          </Box>



          <Box
            sx={{
              display: "flex",
              position: "fixed",
              top: 0,
              left: { xs: 0, sm: 235 },
            }}
          >
            <Appheaderc />
          </Box>
        </Box>


        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    );
  }
}

export default BlogsSection;
