import React, { useState } from 'react';
import JoditEditor from 'jodit-react';

const editorConfig = {
	readonly: false,
	toolbar: true,
	spellcheck: true,
	language: 'en',
	toolbarButtonSize: 'medium',
	// toolbarAdaptive: false,
	showCharsCounter: true,
	showWordsCounter: true,
	showXPathInStatusbar: false,
	askBeforePasteHTML: true,
	askBeforePasteFromWord: true,
	//defaultActionOnPaste: "insert_clear_html",
	// buttons: buttons,
	uploader: {
		insertImageAsBase64URI: true
	},
	width:'100%',
	minHeight: 300,
	controls: {
		font: {
			command: 'fontname',
			list: {
				"'Open Sans',sans-serif": 'Open Sans',
				'Helvetica,sans-serif': 'Helvetica',
				'Arial,Helvetica,sans-serif': 'Arial',
				'Georgia,serif': 'Georgia',
				'Impact,Charcoal,sans-serif': 'Impact',
				'Tahoma,Geneva,sans-serif': 'Tahoma',
				"'Times New Roman',Times,serif": 'Times New Roman',
				'Verdana,Geneva,sans-serif': 'Verdana',
				'Consolas,monaco,monospace': 'Consolas'
			}
		}
	}
};


function Editor() {
	const [data, setData] = useState(null);
	console.log(data);

	return (
		<div
			className="App"
			style={{ maxWidth: editorConfig.width, margin: '0 auto' }}
		>
			<JoditEditor
				value={data}
				config={editorConfig}
				onChange={(value) => setData(value)}
				onBlur={(value,event) => console.log(event)}
			/>
			<div
				style={{
                    display:'none',
					fontFamily: 'Helvetica, Arial, sans-serif',
					fontSize: '14px'
				}}
				dangerouslySetInnerHTML={{ __html: data }}
			></div>
		</div>
	);
}

export default  Editor
