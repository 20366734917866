
import React, { Component } from 'react'
//import {Paper} from '@mui/material'
import { Avatar, Box, Container,Link, Paper,Modal ,Typography ,MenuItem,IconButton,Button, Divider, AppBar, Card} from '@mui/material'
import man from '../src/img/logo.png'
import MenuIcon from '@mui/icons-material/Menu';

import DynamicFormIcon from '@mui/icons-material/DynamicForm';

import Badge from '@mui/material/Badge';
import Stack from '@mui/material/Stack';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import Toolbar from '@mui/material/Toolbar';
import DashboardIcon from '@mui/icons-material/Dashboard';
import GroupIcon from '@mui/icons-material/Group';

import { useNavigate,useLocation,useMatch } from 'react-router-dom';
import BugReportIcon from '@mui/icons-material/BugReport';
import PageviewIcon from '@mui/icons-material/Pageview';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import SettingsIcon from '@mui/icons-material/Settings';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import CampaignIcon from '@mui/icons-material/Campaign';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import PaidIcon from '@mui/icons-material/Paid';
import LogoutIcon from '@mui/icons-material/Logout';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import PeopleIcon from '@mui/icons-material/People';
import SubjectIcon from '@mui/icons-material/Subject';
import SpeedIcon from '@mui/icons-material/Speed';






export class Appheader extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
       open:false,
       data:"",
       textcolor:"#33339c",
       bgcolor:"",
 
       id:1,
       pathname:this.props.location.pathname,
 
       opencompaign:false,
       openrole:false,

    }
  }


  




  render() {
    return (
      <div>
      <Container maxWidth='lg'>
<Paper elevation={1}  sx={{height:60,backgroundColor:'#fff',borderRadius:2,position:'fixed',width:'100%',marginLeft:{xs:-2,sm:0}}}>
<Box sx={{display:'flex',justifyContent:'space-between',backgroundColor:'#fff',borderRadius:2}}>


<Box onClick={()=>this.setState({open:true})} sx={{display:'flex',justifyContent:'center',height:60,alignItems:'center'}}>
<IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ ml:1,display:{xs:'block',sm:'none',mt:2} }}
        >
          <MenuIcon sx={{}} />
        </IconButton>

</Box>



<Box sx={{display:'flex',justifyContent:'center',height:60,alignItems:'center'}}>



<Box sx={{marginRight:{xs:1,sm:35},display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
<Box>
  <Typography sx={{paddingLeft:0.5,paddingRight:0.5,fontSize:13,fontFamily:'sans-serif',fontWeight:'700'}}>INCC</Typography>
  <Typography sx={{paddingLeft:0.5,paddingRight:0.5,fontSize:11,fontFamily:'sans-serif',color:'#42526e'}}>Admin</Typography>
</Box>

</Box>


</Box>



</Box>
</Paper>
</Container>




<Box sx={{display:{xs:'block',sm:'none'}}}>
<Modal
  open={this.state.open}
  sx={{
    display:{xs:'block',sm:'none'}
  }}
  onClose={this.handleClose}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box sx={{display:'flex',justifyContent:'left',alignItems:'center',height:'100%',width:'100%'}} onClick={()=>this.setState({open:false})}>
 <Paper sx={{ minHeight: "100vh",width:240, backgroundColor: "#1b75d3", mt:0,borderRadius:0,borderWidth:0.5,borderColor:'grey'}}>
<br/>
<br/>
<br/>




<Box
                sx={{
                  marginLeft: 0,
                  marginRight:0,
                  height: 40,
                  backgroundColor:
                    this.state.pathname == "/dashboard" ? "#ebebf5" : "#1b75d3",
                  display: "flex",
                  justifyContent: "left",
                  mt: 1,
                  borderRadius:0,
                  alignItems: "center",
                }}
                onClick={()=>this.props.navigate('/dashboard')}
              >
                <Box
                  sx={{ display: "flex", flexDirection: "row", width: "100%" }}
                >
                  <DashboardIcon
                    sx={{
                      marginLeft: 2,
                      color: this.state.pathname == "/dashboard" ? "#008ffb" : "#fff",
                    }}
                  />
                  <Box sx={{ width: "100%", marginLeft: "25%" }}>
                    <Link style={{ textDecoration: "none" }}>
                      <Typography
                        sx={{
                          textDecoration: "none",
                          textAlign: "left",
                          fontSize: 12,
                          fontWeight: "500",
                          mt:0.5,
                          color:
                            this.state.pathname == "/dashboard" ? "#008ffb" : "#fff",
                          marginLeft: -5,
                        }}
                      >
                        DASHBOARD
                      </Typography>
                    </Link>
                  </Box>
                </Box>
              </Box>




              <Box
                sx={{
                  marginLeft: 0,
                  marginRight: 0,
                  height: 40,
                  backgroundColor:
                  this.state.pathname == "/user" ? "#ebebf5" : "#1b75d3",
                  display: "flex",
                  justifyContent: "left",
                  mt: 1,
                  borderRadius: 0,
                  alignItems: "center",
                }}
                onClick={()=>this.props.navigate('/user')}
              >
                <Box
                  sx={{ display: "flex", flexDirection: "row", width: "100%" }}
                >
                  <PeopleIcon
                    sx={{
                      marginLeft: 2,
                      color: this.state.pathname == "/user" ? "#008ffb" : "#fff",
                    }}
                  />
                  <Box sx={{ width: "100%", marginLeft: "25%" }}>
                    <Link style={{ textDecoration: "none" }}>
                      <Typography
                        sx={{
                          textDecoration: "none",
                          textAlign: "left",
                          fontSize: 12,
                          fontWeight: "500",
                          mt:0.5,
                          color:
                            this.state.pathname == "/user" ? "#008ffb" : "#fff",
                            marginLeft: -5,
                        }}
                      >
                        USERS LIST
                      </Typography>
                    </Link>
                  </Box>
                </Box>
              </Box>



              <Box
                sx={{
                  marginLeft: 0,
                  marginRight: 0,
                  height: 40,
                  backgroundColor:
                  this.state.pathname == "/subject" ? "#ebebf5" : "#1b75d3",
                  display: "flex",
                  justifyContent: "left",
                  mt: 1,
                  borderRadius: 0,
                  alignItems: "center",
                }}
                onClick={()=>this.props.navigate('/subject')}
              >
                <Box
                  sx={{ display: "flex", flexDirection: "row", width: "100%" }}
                >
                  <SubjectIcon
                    sx={{
                      marginLeft: 2,
                      color: this.state.pathname == "/subject" ? "#008ffb" : "#fff",
                    }}
                  />
                  <Box sx={{ width: "100%", marginLeft: "25%" }}>
                    <Link style={{ textDecoration: "none" }}>
                      <Typography
                        sx={{
                          textDecoration: "none",
                          textAlign: "left",
                          fontSize: 12,
                          fontWeight: "500",
                          mt:0.5,
                          color:
                            this.state.pathname == "/subject" ? "#008ffb" : "#fff",
                            marginLeft: -5,
                        }}
                      >
                        SUBJECT LIST
                      </Typography>
                    </Link>
                  </Box>
                </Box>
              </Box>


              <Box
                sx={{
                  marginLeft: 0,
                  marginRight: 0,
                  height: 40,
                  backgroundColor:
                  this.state.pathname == "/question_paper" ? "#ebebf5" : "#1b75d3",
                  display: "flex",
                  justifyContent: "left",
                  mt: 1,
                  borderRadius: 0,
                  alignItems: "center",
                }}
                onClick={()=>this.props.navigate('/question_paper')}
              >
                <Box
                  sx={{ display: "flex", flexDirection: "row", width: "100%" }}
                >
                  <SpeedIcon
                    sx={{
                      marginLeft: 2,
                      color: this.state.pathname == "/question_paper" ? "#008ffb" : "#fff",
                    }}
                  />
                  <Box sx={{ width: "100%", marginLeft: "25%" }}>
                    <Link style={{ textDecoration: "none" }}>
                      <Typography
                        sx={{
                          textDecoration: "none",
                          textAlign: "left",
                          fontSize: 12,
                          fontWeight: "500",
                          mt:0.5,
                          color:
                            this.state.pathname == "/question_paper" ? "#008ffb" : "#fff",
                            marginLeft: -5,
                        }}
                      >
                        QUESTION PAPER 
                      </Typography>
                    </Link>
                  </Box>
                </Box>
              </Box>




              <Box
                sx={{
                  marginLeft: 0,
                  marginRight: 0,
                  height: 40,
                  backgroundColor:
                  this.state.pathname == "/settings" ? "#ebebf5" : "#1b75d3",
                  display: "flex",
                  justifyContent: "left",
                  mt: 1,
                  borderRadius: 0,
                  alignItems: "center",
                }}
                onClick={()=>this.props.navigate('/settings')}
              >
                <Box
                  sx={{ display: "flex", flexDirection: "row", width: "100%" }}
                >
                  <SettingsIcon
                    sx={{
                      marginLeft: 2,
                      color: this.state.pathname == "/settings" ? "#008ffb" : "#fff",
                    }}
                  />
                  <Box sx={{ width: "100%", marginLeft: "25%" }}>
                    <Link style={{ textDecoration: "none" }}>
                      <Typography
                        sx={{
                          textDecoration: "none",
                          textAlign: "left",
                          fontSize: 12,
                          fontWeight: "500",
                          mt:0.5,
                          color:
                            this.state.pathname == "/settings" ? "#008ffb" : "#fff",
                            marginLeft: -5,
                        }}
                      >
                        SETTINGS 
                      </Typography>
                    </Link>
                  </Box>
                </Box>
              </Box>



              <Box
                sx={{
                  marginLeft: 0,
                  marginRight: 0,
                  height: 40,
                  backgroundColor:
                  this.state.pathname == "/transaction" ? "#ebebf5" : "#1b75d3",
                  display: "flex",
                  justifyContent: "left",
                  mt: 1,
                  borderRadius: 0,
                  alignItems: "center",
                }}
                onClick={()=>this.props.navigate('/transaction')}
              >
                <Box
                  sx={{ display: "flex", flexDirection: "row", width: "100%" }}
                >
                  <PaidIcon
                    sx={{
                      marginLeft: 2,
                      color: this.state.pathname == "/transaction" ? "#008ffb" : "#fff",
                    }}
                  />
                  <Box sx={{ width: "100%", marginLeft: "25%" }}>
                    <Link style={{ textDecoration: "none" }}>
                      <Typography
                        sx={{
                          textDecoration: "none",
                          textAlign: "left",
                          fontSize: 12,
                          fontWeight: "500",
                          mt:0.5,
                          color:
                            this.state.pathname == "/transaction" ? "#008ffb" : "#fff",
                            marginLeft: -5,
                        }}
                      >
                        TRANSACTIONS 
                      </Typography>
                    </Link>
                  </Box>
                </Box>
              </Box>




              <Box
                sx={{
                  marginLeft: 0,
                  marginRight: 0,
                  height: 40,
                  backgroundColor:
                  this.state.pathname == "/tickets" ? "#ebebf5" : "#1b75d3",
                  display: "flex",
                  justifyContent: "left",
                  mt: 1,
                  borderRadius: 0,
                  alignItems: "center",
                }}
                onClick={()=>this.props.navigate('/tickets')}
              >
                <Box
                  sx={{ display: "flex", flexDirection: "row", width: "100%" }}
                >
                  <ConfirmationNumberIcon
                    sx={{
                      marginLeft: 2,
                      color: this.state.pathname == "/tickets" ? "#008ffb" : "#fff",
                    }}
                  />
                  <Box sx={{ width: "100%", marginLeft: "25%" }}>
                    <Link style={{ textDecoration: "none" }}>
                      <Typography
                        sx={{
                          textDecoration: "none",
                          textAlign: "left",
                          fontSize: 12,
                          fontWeight: "500",
                          mt:0.5,
                          color:
                            this.state.pathname == "/tickets" ? "#008ffb" : "#fff",
                            marginLeft: -5,
                        }}
                      >
                        TICKETS LIST
                      </Typography>
                    </Link>
                  </Box>
                </Box>
              </Box>





              <Box
                sx={{
                  marginLeft: 0,
                  marginRight: 0,
                  height: 40,
                  backgroundColor:
                  this.state.pathname == "/blog" ? "#ebebf5" : "#1b75d3",
                  display: "flex",
                  justifyContent: "left",
                  mt: 1,
                  borderRadius: 0,
                  alignItems: "center",
                }}
                onClick={()=>this.props.navigate('/blog')}
              >
                <Box
                  sx={{ display: "flex", flexDirection: "row", width: "100%" }}
                >
                  <DynamicFeedIcon
                    sx={{
                      marginLeft: 2,
                      color: this.state.pathname == "/blog" ? "#008ffb" : "#fff",
                    }}
                  />
                  <Box sx={{ width: "100%", marginLeft: "25%" }}>
                    <Link style={{ textDecoration: "none" }}>
                      <Typography
                        sx={{
                          textDecoration: "none",
                          textAlign: "left",
                          fontSize: 12,
                          fontWeight: "500",
                          mt:0.5,
                          color:
                            this.state.pathname == "/blog" ? "#008ffb" : "#fff",
                            marginLeft: -5,
                        }}
                      >
                        BLOGS LIST
                      </Typography>
                    </Link>
                  </Box>
                </Box>
              </Box>







              <Box
                sx={{
                  marginLeft: 0,
                  marginRight: 0,
                  height: 40,
                  backgroundColor:
                  this.state.pathname == "/docs" ? "#ebebf5" : "#1b75d3",
                  display: "flex",
                  justifyContent: "left",
                  mt: 1,
                  borderRadius: 0,
                  alignItems: "center",
                }}
                onClick={()=>this.props.navigate('/docs')}
              >
                <Box
                  sx={{ display: "flex", flexDirection: "row", width: "100%" }}
                >
                  <PictureAsPdfIcon
                    sx={{
                      marginLeft: 2,
                      color: this.state.pathname == "/docs" ? "#008ffb" : "#fff",
                    }}
                  />
                  <Box sx={{ width: "100%", marginLeft: "25%" }}>
                    <Link style={{ textDecoration: "none" }}>
                      <Typography
                        sx={{
                          textDecoration: "none",
                          textAlign: "left",
                          fontSize: 12,
                          fontWeight: "500",
                          mt:0.5,
                          color:
                            this.state.pathname == "/docs" ? "#008ffb" : "#fff",
                            marginLeft: -5,
                        }}
                      >
                       DOCUMENTS SECTION
                      </Typography>
                    </Link>
                  </Box>
                </Box>
              </Box>




              <Box
                sx={{
                  marginLeft: 0,
                  marginRight: 0,
                  height: 40,
                  backgroundColor:
                  this.state.pathname == "/teeest" ? "#ebebf5" : "#1b75d3",
                  display: "flex",
                  justifyContent: "left",
                  mt: 1,
                  borderRadius: 0,
                  alignItems: "center",
                }}
                onClick={()=>this.props.navigate('/teseet')}
              >
                <Box
                  sx={{ display: "flex", flexDirection: "row", width: "100%" }}
                >
                  <LogoutIcon
                    sx={{
                      marginLeft: 2,
                      color: this.state.pathname == "/eeusers" ? "#008ffb" : "#fff",
                    }}
                  />
                  <Box sx={{ width: "100%", marginLeft: "25%" }}>
                    <Link style={{ textDecoration: "none" }}>
                      <Typography
                        sx={{
                          textDecoration: "none",
                          textAlign: "left",
                          fontSize: 13,
                          fontWeight: "500",
                          mt:0.5,
                          color:
                            this.state.pathname == "/useeers" ? "#008ffb" : "#fff",
                            marginLeft: -5,
                        }}
                      >
                        LOG OUT  
                      </Typography>
                    </Link>
                  </Box>
                </Box>
              </Box>


          

       











</Paper>
</Box>
</Modal>
</Box>


    </div>
    )
  }
}

export default Appheader

export function Appheaderc(props){
  const navigate = useNavigate();
  const location = useLocation();
  return (<Appheader location={location} navigate={navigate}></Appheader>)
}


